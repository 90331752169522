var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-component--advertise-with-us pb-md-15" },
    [
      _c(
        "div",
        { staticClass: "desktop-content d-none d-lg-block expandHeader" },
        [
          _c(
            "v-container",
            { staticClass: "banner-content-container pt-16" },
            [
              _c(
                "div",
                {
                  class: [
                    _vm.$vuetify.breakpoint.mdAndDown
                      ? "mew-subtitle"
                      : "mew-title",
                    "font-weight-regular white--text mb-11",
                  ],
                },
                [_vm._v(" Advertise with XOW ")]
              ),
              _c(
                "v-row",
                { staticClass: "pl-12" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-12 d-flex align-start",
                      attrs: { cols: "4" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "py-3 px-5 text-center white--text d-flex align-center flex-column work-together-container",
                        },
                        [
                          _c("span", { staticClass: "mew-heading-4" }, [
                            _vm._v(" Let's work together to achieve "),
                            _c("br"),
                            _vm._v(" your marketing goals!"),
                          ]),
                          _c("mew-button", {
                            staticClass: "mt-4",
                            attrs: {
                              "btn-style": "background",
                              "color-theme": "primary",
                              "btn-size": "xlarge",
                              title: "Contact Us",
                              "btn-link": "mailto:@info@myetherwallet.com",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("v-col", { staticClass: "pt-12", attrs: { cols: "8" } }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/backgrounds/mew-orbit.png"),
                        alt: "mew orbit",
                        "max-width": "800px",
                        width: "100%",
                      },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mobile-content d-block d-lg-none expandHeader pt-5" },
        [
          _c(
            "v-container",
            [
              _c(
                "div",
                {
                  staticClass:
                    "mew-title font-weight-regular white--text text-center",
                },
                [_vm._v(" Advertise With XOW ")]
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-4 text-center", attrs: { cols: "12" } },
                    [
                      _c("img", {
                        staticClass: "orbit-img",
                        attrs: {
                          src: require("@/assets/images/backgrounds/mew-orbit.png"),
                          alt: "mew orbit",
                          width: "100%",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-12 d-flex align-center justify-center",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pa-5 text-center white--text d-flex align-center flex-column work-together-container",
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "mew-heading-4 font-weight-bold" },
                            [
                              _vm._v(" Let's work together to achieve "),
                              _c("br"),
                              _vm._v(" your marketing goals!"),
                            ]
                          ),
                          _c("mew-button", {
                            staticClass: "mt-4",
                            attrs: {
                              "btn-style": "background",
                              "color-theme": "primary",
                              "btn-size": "xlarge",
                              title: "Contact Us",
                              "btn-link": "mailto:@info@myetherwallet.com",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }