var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("success-modal", {
        attrs: {
          "show-success-modal": _vm.showSuccessModal,
          "show-success-swap": _vm.showSuccessSwap,
          "success-title": _vm.successTitle,
          "reset-success": _vm.resetSuccess,
          reset: _vm.reset,
          network: _vm.network,
          links: _vm.links,
          "success-body-text": _vm.successBodyText,
          "has-close-button": false,
        },
      }),
      _c("cross-chain-confirmation", {
        attrs: {
          "show-cross-chain-modal": _vm.showCrossChainModal,
          "tx-obj": _vm.tx,
          title: _vm.title,
          reset: _vm.rejectTransaction,
          "sent-btc": _vm.sendCrossChain,
        },
        on: { close: _vm.rejectTransaction },
      }),
      _c("app-modal", {
        attrs: {
          show: _vm.showTxOverlay,
          title: _vm.title !== "" ? _vm.title : "Confirmation",
          close: _vm.rejectTransaction,
          "btn-action": _vm.btnAction,
          "btn-enabled": _vm.disableBtn,
          "btn-text": _vm.toNonEth ? "Proceed with swap" : "Confirm & Send",
          scrollable: true,
          anchored: true,
          width: "650",
        },
        scopedSlots: _vm._u([
          {
            key: "dialogBody",
            fn: function () {
              return [
                _c(
                  "v-card-text",
                  {
                    ref: "scrollableContent",
                    staticClass: "py-0 px-4 px-md-0",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "px-4 py-6 pr-6 textBlack2--text border-radius--5px mb-5",
                      },
                      [
                        _c("b", [_vm._v("Please double check everything.")]),
                        _vm._v(
                          " XOW team will not be able to reverse your transaction once it's submitted. You will still be charged gas fee even if the transaction fails. "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.getArticle("my-txn-failed-charged"),
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                          [_vm._v("Learn more.")]
                        ),
                      ]
                    ),
                    !_vm.isContractCreation
                      ? _c(
                          "div",
                          [
                            !_vm.isSwap
                              ? _c("confirmation-send-transaction-details", {
                                  attrs: {
                                    to: _vm.txTo,
                                    network: _vm.network,
                                    "tx-fee": _vm.txFee,
                                    "tx-fee-usd": _vm.txFeeUSD,
                                    value: _vm.value,
                                    "to-tx-data": _vm.tx.toTxData,
                                    "to-details": _vm.allToDetails,
                                    "send-currency": _vm.sendCurrency,
                                    "is-web3-wallet": _vm.hasGasPriceOption,
                                  },
                                })
                              : _c("confirmation-swap-transaction-details", {
                                  attrs: {
                                    to: _vm.swapInfo.to,
                                    from: _vm.swapInfo.from,
                                    "from-img": _vm.swapInfo.fromImg,
                                    "from-type": _vm.swapInfo.fromType,
                                    "to-type": _vm.swapInfo.toType,
                                    "to-img": _vm.swapInfo.toImg,
                                    "from-val": _vm.swapInfo.fromVal,
                                    "to-val": _vm.swapInfo.toVal,
                                    provider: _vm.swapInfo.selectedProvider,
                                    "to-usd": _vm.swapInfo.toUsdVal,
                                    "from-usd": _vm.swapInfo.fromUsdVal,
                                    "tx-fee": _vm.swapInfo.txFee,
                                    "gas-price-type": _vm.swapInfo.gasPriceType,
                                    "is-hardware": _vm.isHardware,
                                    "is-to-non-eth": _vm.toNonEth,
                                    "to-currency": _vm.swapInfo.toType,
                                    "to-address": _vm.swapInfo.to,
                                  },
                                }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isOnLedger
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "ledger-warning d-flex justify-space-between px-4 py-6 border-radius--5px mb-5",
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("v-img", {
                                  staticClass: "ml-15",
                                  attrs: {
                                    src: require("@/assets/images/icons/hardware-wallets/Ledger-Nano-X-Label-Icon.svg"),
                                    alt: "Ledger Wallet",
                                    "max-width": "11em",
                                    "max-height": "2.5em",
                                    contain: "",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              { staticClass: "textBlack2--text ml-2" },
                              [
                                _c("b", [_vm._v("Using Ledger?")]),
                                _vm._v(
                                  " Consider turning off 'debug data' before proceeding. Additional steps associated with the 'debug feature' on Ledger may be required to approve this transaction. "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showConfirmWithWallet
                      ? _c("confirm-with-wallet", {
                          attrs: {
                            "tx-length":
                              _vm.unsignedTxArr.length > 0
                                ? _vm.unsignedTxArr.length
                                : 1,
                            signed: _vm.signingPending,
                            error: _vm.error,
                          },
                        })
                      : _vm._e(),
                    _c(
                      "v-expansion-panels",
                      {
                        staticClass: "expansion-border",
                        attrs: { accordion: "", multiple: "", flat: "" },
                        model: {
                          value: _vm.panel,
                          callback: function ($$v) {
                            _vm.panel = $$v
                          },
                          expression: "panel",
                        },
                      },
                      _vm._l(_vm.transactions, function (transaction, i) {
                        return _c(
                          "v-expansion-panel",
                          {
                            key: "" + transaction.title + transaction.value + i,
                            staticClass: "bgWalletBlockDark",
                            class: {
                              "expansion-panel-border-bottom":
                                _vm.transactions.length > 1 &&
                                i !== _vm.transactions.length - 1,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.scrollToElement(i)
                              },
                            },
                          },
                          [
                            _c(
                              "v-expansion-panel-header",
                              {
                                staticClass: "expansion-header",
                                attrs: { "disable-icon-rotate": _vm.signing },
                              },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "align-center pr-7 pl-2" },
                                  [
                                    _c("p", { staticClass: "ma-0 pl-1" }, [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            "Transaction " +
                                              _vm._s(
                                                _vm.transactions.length > 1
                                                  ? "" + (i + 1)
                                                  : "details"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("br"),
                                      _vm.isBatch
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ma-0 mew-label greyPrimary--text",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.isSwap ? "Swap " : ""
                                                ) +
                                                  " part " +
                                                  _vm._s(i + 1) +
                                                  " - " +
                                                  _vm._s(
                                                    _vm.dataToAction(
                                                      _vm.unsignedTxArr[i]
                                                    )
                                                  )
                                              ),
                                            ]
                                          )
                                        : _vm.dataToAction(_vm.tx) !== ""
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ma-0 mew-label greyPrimary--text",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.dataToAction(_vm.tx))
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _c("v-spacer"),
                                    _vm.signing
                                      ? _c(
                                          "div",
                                          [
                                            _c("v-progress-circular", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.error === "" &&
                                                    _vm.isBatch &&
                                                    _vm.signedTxArray.length <
                                                      i + 1,
                                                  expression:
                                                    "\n                      error === '' && isBatch && signedTxArray.length < i + 1\n                    ",
                                                },
                                              ],
                                              staticClass: "pr-7",
                                              attrs: {
                                                indeterminate: "",
                                                color: "greenPrimary",
                                                size: "20",
                                                width: "2",
                                              },
                                            }),
                                            _c("v-progress-circular", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.isBatch &&
                                                    Object.keys(
                                                      _vm.signedTxObject
                                                    ).length === 0,
                                                  expression:
                                                    "\n                      !isBatch && Object.keys(signedTxObject).length === 0\n                    ",
                                                },
                                              ],
                                              staticClass: "pr-7",
                                              attrs: {
                                                indeterminate: "",
                                                color: "greenPrimary",
                                                size: "20",
                                                width: "2",
                                              },
                                            }),
                                            _c(
                                              "v-icon",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      !_vm.isBatch &&
                                                      Object.keys(
                                                        _vm.signedTxObject
                                                      ).length !== 0,
                                                    expression:
                                                      "\n                      !isBatch && Object.keys(signedTxObject).length !== 0\n                    ",
                                                  },
                                                ],
                                                attrs: {
                                                  color: "greenPrimary",
                                                },
                                              },
                                              [_vm._v(" mdi-check ")]
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.error === "" &&
                                                      _vm.isBatch &&
                                                      _vm.signedTxArray
                                                        .length >=
                                                        i + 1,
                                                    expression:
                                                      "\n                      error === '' && isBatch && signedTxArray.length >= i + 1\n                    ",
                                                  },
                                                ],
                                                attrs: {
                                                  color: "greenPrimary",
                                                },
                                              },
                                              [_vm._v(" mdi-check ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-expansion-panel-content",
                              { attrs: { id: i } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "pa-6 pt-0" },
                                  _vm._l(transaction, function (txVal) {
                                    return _c(
                                      "v-row",
                                      {
                                        key: "" + txVal.title + txVal.value,
                                        staticClass:
                                          "d-flex justify-space-between mt-3",
                                        attrs: { "no-gutters": "" },
                                      },
                                      [
                                        _vm.shouldDisplayDetail(txVal.title)
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "d-flex d-sm-block ma-0 greyPrimary--text",
                                                attrs: { cols: "12", md: "3" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(txVal.title) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.shouldDisplayDetail(txVal.title)
                                          ? _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "9" },
                                              },
                                              [
                                                _c("scroll-block", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "data-values text-md-right",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(txVal.value) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _vm.toNonEth
                      ? _c(
                          "div",
                          { staticClass: "pt-4" },
                          [
                            _vm._v(
                              " By clicking 'Proceed with swap', I agree to the "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://changelly.com/aml-kyc",
                                  target: "_blank",
                                },
                              },
                              [_vm._v(" Changelly AML/KYC ")]
                            ),
                            _vm._v(" and "),
                            _c(
                              "router-link",
                              {
                                attrs: { to: _vm.termRoute, target: "_blank" },
                              },
                              [_vm._v("Terms of Service")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "mew-overlay",
        {
          attrs: {
            footer: _vm.footer,
            "show-overlay": _vm.showSignOverlay,
            title: _vm.title ? _vm.title : "Message",
            close: _vm.reset,
            "content-size": "large",
          },
        },
        [
          _c("confirmation-messsage", {
            ref: "messageConfirmationContainer",
            attrs: { msg: _vm.signature, copy: _vm.copyToClipboard },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }