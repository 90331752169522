var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("div", { staticClass: "py-7" }),
      _c("v-sheet", { attrs: { align: "center" } }, [
        _c("h1", { staticClass: "textDark--text pb-5" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm._f("lokalise")(
                  _vm.$t("home.community-title"),
                  "home.community-title"
                )
              ) +
              " "
          ),
        ]),
      ]),
      _c(
        "v-sheet",
        {
          staticClass: "mx-auto",
          attrs: { color: "transparent", "max-width": "900px" },
        },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            _vm._l(_vm.socials, function (p, key) {
              return _c(
                "v-col",
                {
                  key: key,
                  attrs: { align: "center", cols: "4", sm: "2", md: "1" },
                },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: p.link, target: "_blank" },
                      on: {
                        click: function ($event) {
                          return _vm.trackSocialClick(p)
                        },
                      },
                    },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: p.img,
                            expression: "p.img",
                          },
                        ],
                        staticClass: "align-center justify-center",
                        staticStyle: { "border-radius": "50%" },
                        attrs: {
                          "max-height": "40px",
                          "max-width": "40px",
                          contain: "",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "pt-2 textLight--text mew-label text-center",
                    },
                    [_vm._v(" " + _vm._s(p.label) + " ")]
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "py-7" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }