var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "component-container" },
    [
      _c("div", { staticClass: "py-3 py-md-12", attrs: { id: "dapps" } }),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "flex-column-reverse flex-lg-row" },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-content pr-12 mt-12 mt-lg-0 pb-12 pb-lg-0",
                  attrs: { cols: "12", lg: "5" },
                },
                [
                  _c("app-block-title", {
                    staticClass: "mb-12 pl-12",
                    attrs: { "no-page-title": "", data: _vm.titleData },
                  }),
                  _c("div", { staticClass: "mb-4 d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "mr-3" },
                      [
                        _c("v-img", {
                          attrs: {
                            src: require("@/assets/images/icons/icon-dapps-mew.svg"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "mb-1 font-weight-bold subtitle-1" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t("howItWorks.dapps.info-one.title"),
                                  "howItWorks.dapps.info-one.title"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("lokalise")(
                                _vm.$t("howItWorks.dapps.info-one.text"),
                                "howItWorks.dapps.info-one.text"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "mr-3" },
                      [
                        _c("v-img", {
                          attrs: {
                            src: require("@/assets/images/icons/icon-submit-mew.svg"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "mb-1 font-weight-bold subtitle-1" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t("howItWorks.dapps.info-two.title"),
                                  "howItWorks.dapps.info-two.title"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("lokalise")(
                                _vm.$t("howItWorks.dapps.info-two.text"),
                                "howItWorks.dapps.info-two.text"
                              )
                            ) +
                            " "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.myetherwallet.com/dapp-submission",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t("howItWorks.dapps.info-two.link"),
                                  "howItWorks.dapps.info-two.link"
                                )
                              )
                            ),
                          ]
                        ),
                        _vm._v(". "),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "7" } },
                [
                  _c("v-img", {
                    staticClass: "block-image",
                    attrs: {
                      src: require("@/assets/images/snippets/new-dapps-page.jpg"),
                      contain: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }