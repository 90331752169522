var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ask-ai-container" }, [
    _vm._m(0),
    _c("div", { staticClass: "chat-box" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.question,
            expression: "question",
          },
        ],
        attrs: { placeholder: "Type your question here..." },
        domProps: { value: _vm.question },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.question = $event.target.value
          },
        },
      }),
      _c(
        "button",
        { attrs: { disabled: _vm.isSubmitting }, on: { click: _vm.askAI } },
        [
          !_vm.isSubmitting
            ? _c("span", [_vm._v("Submit")])
            : _c("span", [_vm._v("Loading...")]),
        ]
      ),
    ]),
    _vm.response
      ? _c("div", { staticClass: "response-container" }, [
          _c("div", {
            staticClass: "response-message",
            domProps: { innerHTML: _vm._s(_vm.renderedResponse) },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h1", [_vm._v("Ask AI")]),
      _c("p", [_vm._v("Powered by CodeRunAI")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }