var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("the-default-header", { on: { openMobileMenu: _vm.handleOpen } }),
      _c("v-main", { staticClass: "js-body" }, [_c("router-view")], 1),
      _c("the-default-footer"),
      _c("the-mobile-menu", {
        attrs: { "is-open": _vm.mobileOpen },
        on: { closeMobileMenu: _vm.handleClose },
      }),
      _c("gdpr-banner"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }