var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew-overlay",
    {
      attrs: {
        "show-overlay": _vm.open,
        title: _vm.title,
        back: _vm.showBack ? null : _vm.back,
        close: _vm.overlayClose,
        "content-size": "xlarge",
      },
    },
    [
      _vm.step === 1
        ? _c(
            "div",
            {
              class: [
                "pa-5 mb-4 full-width text-center rounded subtitle-container",
                _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                  ? "mt-3"
                  : "",
              ],
            },
            [
              _c(
                "span",
                { staticClass: "full-width" },
                [
                  _vm._v(
                    "The highest standard of security in the crypto space. "
                  ),
                  _c("router-link", { attrs: { to: "/buy-hardware" } }, [
                    _vm._v(" Get a Hardware Wallet today "),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.step === 1
        ? _c(
            "div",
            [
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "", justify: "start" } },
                _vm._l(_vm.buttons, function (button) {
                  return _c(
                    "v-col",
                    {
                      key: button.label,
                      staticClass: "button-container full-width pa-1",
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _c(
                        "mew-button",
                        {
                          staticStyle: { height: "90px" },
                          attrs: {
                            "has-full-width": "",
                            "color-theme": "greyMedium",
                            "btn-style": "outline",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return function () {
                                button.fn()
                                button.bluetooth && !_vm.bluetooth
                                  ? (_vm.bluetoothModal = true)
                                  : _vm.setWalletInstance(button)
                              }.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-left d-flex align-center",
                              staticStyle: { width: "100%" },
                            },
                            [
                              _c("img", {
                                staticClass: "mr-4",
                                attrs: { width: "40", src: button.icon },
                              }),
                              _c(
                                "div",
                                { staticClass: "mew-heading-3 textDark--text" },
                                [_vm._v(" " + _vm._s(button.label) + " ")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "500" },
          model: {
            value: _vm.bluetoothModal,
            callback: function ($$v) {
              _vm.bluetoothModal = $$v
            },
            expression: "bluetoothModal",
          },
        },
        [
          _c(
            "v-sheet",
            { staticClass: "pa-5", attrs: { color: "white" } },
            [
              _c("h2", { staticClass: "mb-5 text-center" }, [
                _vm._v("Bluetooth Required"),
              ]),
              _c(
                "v-list",
                [
                  _c("h3", [_vm._v("Supported Browsers")]),
                  _vm._l(_vm.supportedBrowsers, function (browser, i) {
                    return _c(
                      "v-list-item",
                      { key: i },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(" " + _vm._s(browser) + " "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c("h4", { staticClass: "mb-5" }, [
                _vm._v("* Remember to enable bluetooth on your device"),
              ]),
              _c("h3", { staticClass: "mb-5 text-center" }, [
                _vm._v("Enable bluetooth on supported browsers"),
              ]),
              _vm._l(_vm.enableBluetooth, function (browser, i) {
                return _c(
                  "v-expansion-panels",
                  { key: i, staticClass: "mt-1", attrs: { accordion: "" } },
                  [
                    _c(
                      "v-expansion-panel",
                      [
                        _c("v-expansion-panel-header", [
                          _vm._v(" " + _vm._s(browser.title) + " "),
                        ]),
                        _vm._l(
                          browser.direction,
                          function (direction, browserIndex) {
                            return _c(
                              "v-expansion-panel-content",
                              { key: browserIndex },
                              [
                                _c("h4", { staticClass: "mb-3" }, [
                                  _vm._v(_vm._s(direction.title)),
                                ]),
                                _vm._l(direction.steps, function (s, index) {
                                  return _c("ul", { key: index }, [
                                    s.length
                                      ? _c("li", { staticClass: "mb-1" }, [
                                          _vm._v(" " + _vm._s(s) + " "),
                                        ])
                                      : _vm._e(),
                                    !s.length
                                      ? _c("li", { staticClass: "mb-1" }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: s.link,
                                                target: "_blank",
                                              },
                                            },
                                            [_vm._v("Learn More")]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                }),
                              ],
                              2
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                )
              }),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("mew-button", {
                    staticClass: "mt-6",
                    attrs: {
                      title: "Close",
                      "btn-size": "xlarge",
                      "btn-style": "outline",
                    },
                    nativeOn: {
                      click: function ($event) {
                        _vm.bluetoothModal = false
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm.step <= _vm.walletInitialized
        ? _c(
            "div",
            { staticClass: "full-width mt-4 mt-lg-0" },
            [
              _vm.onBitbox2
                ? _c("access-wallet-bitbox", {
                    attrs: {
                      paths: _vm.paths,
                      "set-path": _vm.setPath,
                      "hw-wallet-instance": _vm.hwWalletInstance,
                      unlock: _vm.bitbox02Unlock,
                      "device-not-paired": _vm.bitBox2NotPaired,
                      "device-connected": _vm.bitBox2Connected,
                      "device-unpaired": _vm.bitBox2Unpaired,
                      "device-pairing-code": _vm.bitBox2PairingCode,
                      "device-confirmed": _vm.bitBox2Confirmed,
                      "device-initialized": _vm.bitBox2Initialized,
                    },
                  })
                : _vm._e(),
              _vm.onKeepkey
                ? _c("access-wallet-keepkey", {
                    attrs: {
                      paths: _vm.paths,
                      "selected-path": _vm.selectedPath,
                      "handler-loaded": _vm.loaded,
                      "set-path": _vm.setPath,
                    },
                  })
                : _vm._e(),
              _vm.onCoolWallet
                ? _c("access-wallet-cool-wallet", {
                    attrs: {
                      "cool-wallet-unlock": _vm.coolWalletUnlock,
                      "password-error": _vm.passwordError,
                    },
                    on: { password: _vm.setPassword },
                  })
                : _vm._e(),
              _vm.onLedger || _vm.onLedgerX
                ? _c("access-wallet-ledger", {
                    attrs: {
                      "ledger-unlock": _vm.nextStep,
                      "ledger-apps": _vm.ledgerApps,
                      paths: _vm.paths,
                      "selected-path": _vm.selectedPath,
                      "set-path": _vm.setPath,
                    },
                    on: {
                      setBluetoothLedgerUnlock: _vm.setBluetoothLedgerUnlock,
                    },
                  })
                : _vm._e(),
              _vm.onTrezor
                ? _c(
                    "div",
                    [
                      _c("access-wallet-trezor", {
                        attrs: {
                          "trezor-unlock": _vm.trezorUnlock,
                          reset: _vm.reset,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.step > _vm.walletInitialized
        ? _c("access-wallet-address-network", {
            attrs: {
              back: null,
              "hide-path-selection": _vm.onKeepkey || _vm.onLedger,
              "disable-custom-paths": _vm.onLedger,
              "handler-wallet": _vm.hwWalletInstance,
              "selected-path": _vm.selectedPath,
              paths: _vm.paths,
              "hide-networks": _vm.switchAddress,
            },
            on: { unlock: _vm.setHardwareWallet, setPath: _vm.setPath },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }