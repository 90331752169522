var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center" }, [
    _c("h2", { staticClass: "mb-2" }, [
      _vm._v(_vm._s(_vm.$t("flyover.pegout.deposit.thanks"))),
    ]),
    _c("h3", [
      _c(
        "a",
        { staticClass: "mb-4", attrs: { href: _vm.hash, target: "_blank" } },
        [_vm._v(_vm._s(_vm.$t("flyover.pegout.deposit.tx")))]
      ),
    ]),
    _c("h3", [
      _c("p", { staticClass: "mb-2 mt-2" }, [
        _vm._v(_vm._s(_vm.$t("flyover.pegout.deposit.done"))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }