<template>
  <div class="ask-ai-container">
    <div class="header">
      <h1>Ask AI</h1>
      <p>Powered by CodeRunAI</p>
    </div>
    <div class="chat-box">
      <textarea v-model="question" placeholder="Type your question here..."></textarea>
      <button :disabled="isSubmitting" @click="askAI">
        <span v-if="!isSubmitting">Submit</span>
        <span v-else>Loading...</span>
      </button>
    </div>
    <div v-if="response" class="response-container">
      <div class="response-message" v-html="renderedResponse"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import marked from 'marked';

export default {
  data() {
    return {
      question: '',
      response: null,
      isSubmitting: false
    };
  },
  computed: {
    renderedResponse() {
      return this.response ? marked(this.response) : '';
    }
  },
  methods: {
    async askAI() {
      this.isSubmitting = true;
      this.response = null;
      try {
        const result = await axios.post('https://coderun.ai/api/chat/', {
          question: this.question
        }, {
          headers: {
            Authorization: 'Bearer ofk*GHrg1Fmu*dvsHBbXn3SWScp7Xb86we7i1apnP24hrtS1dd',
            'Content-Type': 'application/json'
          }
        });

        this.response = result.data;
      } catch (error) {
        console.error('Error asking AI:', error);
        this.response = 'An error occurred. Please try again.';
      } finally {
        this.isSubmitting = false;
      }
    }
  }
};
</script>

<style scoped>
.ask-ai-container {
  max-width: 700px;
  margin: 50px auto;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #333;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 28px;
  color: #2c3e50;
  font-weight: bold;
  margin: 0;
}

.header p {
  font-size: 14px;
  color: #95a5a6;
}

.chat-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

textarea {
  width: 100%;
  height: 140px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  color: #2c3e50;
  background-color: #f5f5f5;
  resize: none;
  box-sizing: border-box;
  outline: none;
  transition: all 0.3s ease;
}

textarea:focus {
  border-color: #007bff;
  background-color: #fff;
}

button {
  align-self: flex-end;
  padding: 12px 24px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #c0c0c0;
  cursor: not-allowed;
}

.response-container {
  margin-top: 30px;
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
}

.response-message {
  background-color: #f1f7ff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.response-message h3 {
  font-size: 20px;
  color: #007bff;
  margin-bottom: 10px;
}

.response-message p {
  font-size: 16px;
  color: #2c3e50;
}
</style>
