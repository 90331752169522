var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-modal", {
        attrs: {
          show: _vm.showCrossChainModal,
          title: _vm.title,
          close: _vm.locReset,
          "btn-action": _vm.btnAction,
          "btn-text": _vm.buttonTitle,
          "btn-enabled": !_vm.timerFinished,
          "is-persistent": true,
          "accept-only": true,
          width: "560",
        },
        on: { close: _vm.locReset },
        scopedSlots: _vm._u([
          {
            key: "dialogBody",
            fn: function () {
              return [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex display-section mx-auto flex-column pt-8",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "py-3 text-center tableHeader border-radius--5px",
                        },
                        [
                          _c("div", { staticClass: "mew-heading-4" }, [
                            _vm._v("Please Send"),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mew-heading-4 d-flex align-center justify-center pt-2 text-center",
                            },
                            [
                              _c("img", {
                                staticClass: "mr-2",
                                attrs: { src: _vm.fromImg, width: "30" },
                              }),
                              _c(
                                "div",
                                { staticClass: "mew-heading-2 no-decoration" },
                                [_vm._v(" " + _vm._s(_vm.fromVal) + " ")]
                              ),
                              _c("div", { staticClass: "mew-body pl-1" }, [
                                _vm._v(_vm._s(_vm.fromType)),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "py-3 text-center grey-border border-radius--5px my-1",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mew-header-block inputLabel--text mb-0",
                            },
                            [_vm._v(" TO ADDRESS ")]
                          ),
                          _c(
                            "div",
                            {
                              class: [
                                "py-2 text-center mew-body word-break--break-all",
                                _vm.timerFinished ? "disabled--text" : "",
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.timerFinished
                                      ? "Address expired"
                                      : _vm.payinAddress
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          !_vm.timerFinished
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "text-center primary--text cursor--pointer",
                                  on: { click: _vm.copy },
                                },
                                [_vm._v(" Copy Address ")]
                              )
                            : _vm._e(),
                          !_vm.timerFinished
                            ? _c(
                                "div",
                                [
                                  !_vm.hideQr
                                    ? _c("app-qr-code", {
                                        attrs: {
                                          data: _vm.payinAddress,
                                          height: 160,
                                          width: 160,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-center justify-center",
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src: _vm.qrDisabled,
                                      width: "160",
                                      height: "160",
                                      "max-width": "160",
                                      "max-height": "160",
                                    },
                                  }),
                                ],
                                1
                              ),
                          !_vm.timerFinished
                            ? _c(
                                "div",
                                { staticClass: "mew-body textSecondary--text" },
                                [_vm._v(" " + _vm._s(_vm.sendWarning) + " ")]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.timerFinished
                                  ? "error--text"
                                  : "textSecondary--text",
                                "mew-body",
                              ],
                            },
                            [_vm._v(" " + _vm._s(_vm.time) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "py-3 text-center tableHeader border-radius--5px",
                        },
                        [
                          _c("div", { staticClass: "mew-heading-4" }, [
                            _vm._v("You'll get"),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mew-heading-4 d-flex align-center justify-center pt-2 text-center",
                            },
                            [
                              _c("img", {
                                staticClass: "mr-2",
                                attrs: { src: _vm.toImg, width: "30" },
                              }),
                              _c(
                                "div",
                                { staticClass: "mew-heading-2 no-decoration" },
                                [_vm._v(" " + _vm._s(_vm.toVal) + " ")]
                              ),
                              _c("div", { staticClass: "mew-body pl-1" }, [
                                _vm._v(_vm._s(_vm.toType)),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "py-4" },
                    [
                      _c("mew-expand-panel", {
                        attrs: { "panel-items": _vm.panelItems },
                        scopedSlots: _vm._u([
                          {
                            key: "panelBody1",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "px-6 pb-6" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-space-between",
                                    },
                                    [
                                      _c("div", [_vm._v("Refund Address:")]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "word-break--break-all",
                                        },
                                        [_vm._v(_vm._s(_vm.refundAddress))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-space-between pt-2",
                                    },
                                    [
                                      _c("div", [_vm._v("Rate:")]),
                                      _c("div", [
                                        _vm._v(
                                          " 1 " +
                                            _vm._s(_vm.fromCurrency) +
                                            " = " +
                                            _vm._s(_vm.rate) +
                                            " " +
                                            _vm._s(_vm.toCurrency) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm._v(
                        " By sending " +
                          _vm._s(_vm.fromCurrency) +
                          " to the above address, I agree to the "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://changelly.com/aml-kyc",
                            target: "_blank",
                          },
                        },
                        [_vm._v(" Changelly AML/KYC ")]
                      ),
                      _vm._v(" and "),
                      _c(
                        "router-link",
                        { attrs: { to: _vm.termRoute, target: "_blank" } },
                        [_vm._v("Terms of Service")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }