var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("the-layout-header", {
        staticClass: "pt-16",
        attrs: { title: _vm.$t("aboutUs.title") },
      }),
      _c("div", { staticClass: "py-9" }),
      _c("company-what-is-mew"),
      _c("div", { staticClass: "py-7" }),
      _c("company-our-story"),
      _c("div", { staticClass: "py-10" }),
      _c("company-community"),
      _c("div", { staticClass: "py-10" }),
      _c("partners-block"),
      _c("div", { staticClass: "py-9" }),
      _c("mewtopia-block"),
      _c("div", { staticClass: "py-1" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }