var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.showIsAdBanner
      ? _c("div", [
          _vm.locShowBanner && _vm.showBanner
            ? _c("div", { staticClass: "banner-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "close-icon pa-2 pa-sm-2",
                    on: { click: _vm.trackClosing },
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "cursor--pointer",
                        attrs: {
                          size: _vm.$vuetify.breakpoint.smAndDown ? "16" : "24",
                          color: "white",
                        },
                      },
                      [_vm._v(" mdi-close ")]
                    ),
                  ],
                  1
                ),
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/backgrounds/staking-popup-banner.png"),
                    width: "100%",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "banner-text pa-2 pa-sm-5" },
                  [
                    _vm._m(0),
                    _c("mew-button", {
                      staticClass: "mt-2 mt-sm-5 text-blue",
                      attrs: {
                        title: "View Staking Options",
                        "btn-size": _vm.$vuetify.breakpoint.smAndDown
                          ? "small"
                          : "large",
                        "color-theme": "white",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.viewStakingOptions.apply(null, arguments)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ])
      : _c("div", { staticClass: "pb-3" }, [
          _c(
            "a",
            {
              attrs: { href: _vm.adLink, target: "_blank" },
              on: { click: _vm.trackAdClick },
            },
            [_c("img", { attrs: { src: _vm.adBanner, width: "100%" } })]
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "div",
        {
          staticClass:
            "mew-title font-weight-regular white--text override-title",
        },
        [_vm._v(" Make your crypto make crypto ")]
      ),
      _c(
        "div",
        {
          staticClass:
            "mew-heading-4 font-weight-regular faded d-none d-sm-block",
        },
        [_vm._v(" Stake your ETH and get rewarded by Ethereum blockchain. ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }