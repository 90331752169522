var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "white-sheet",
    { staticClass: "px-5 px-lg-7 py-5 justify-space-between" },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("v-col", { attrs: { cols: "11" } }, [
            _c(
              "div",
              { class: [_vm.draggable ? "ml-7" : "", "mew-heading-2 mb-3"] },
              [_vm._v(" " + _vm._s(_vm.actualTitle) + " ")]
            ),
          ]),
          _c(
            "v-col",
            {
              attrs: { cols: "1", align: "right" },
              on: { click: _vm.toggleDropdown },
            },
            [
              _c("v-icon", { attrs: { color: "black" } }, [
                _vm._v(_vm._s(_vm.chevronIcon)),
              ]),
            ],
            1
          ),
          _vm.dropdown
            ? _c(
                "v-col",
                { staticClass: "px-0", attrs: { cols: "12" } },
                _vm._l(_vm.actualNotifications, function (data, key) {
                  return _c(
                    "div",
                    { key: key, staticClass: "mb-2" },
                    [
                      _c("mew-notification", {
                        staticClass: "px-0",
                        attrs: {
                          "show-indicator": false,
                          notification: data.notification,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.markNotificationAsRead(data)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }