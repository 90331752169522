var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("mew-module", {
        staticClass: "d-flex flex-grow-1 pt-6",
        attrs: {
          title: "Send Offline",
          "has-elevation": true,
          "has-indicator": true,
        },
        scopedSlots: _vm._u([
          {
            key: "moduleBody",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { staticClass: "mt-5" },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "pr-sm-1 pt-0 pb-0 pb-sm-4",
                        attrs: { cols: "12", sm: "6" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "position--relative" },
                          [
                            _c("mew-select", {
                              attrs: {
                                label: "Token",
                                items: _vm.tokens,
                                "is-custom": true,
                              },
                              model: {
                                value: _vm.selectedCurrency,
                                callback: function ($$v) {
                                  _vm.selectedCurrency = $$v
                                },
                                expression: "selectedCurrency",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "pl-sm-1 pt-0 pb-2 pb-sm-4",
                        attrs: { cols: "12", sm: "6" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "position--relative" },
                          [
                            _c("mew-input", {
                              staticClass: "SendOfflineAmountInput",
                              attrs: {
                                label: "Amount",
                                placeholder: "0",
                                "error-messages": _vm.amountErrors,
                                type: "number",
                              },
                              model: {
                                value: _vm.amount,
                                callback: function ($$v) {
                                  _vm.amount = $$v
                                },
                                expression: "amount",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "pt-4 pb-2", attrs: { cols: "12" } },
                      [
                        _c("module-address-book", {
                          ref: "addressInput",
                          staticClass: "SendOfflineAddressBook",
                          on: { setAddress: _vm.setAddress },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("mew-input", {
                          staticClass: "SendOfflineNonceInput",
                          attrs: {
                            label: _vm.$t("sendTx.nonce"),
                            placeholder: "0",
                            type: "number",
                            "error-messages": _vm.nonceErrors,
                          },
                          model: {
                            value: _vm.localNonce,
                            callback: function ($$v) {
                              _vm.localNonce = $$v
                            },
                            expression: "localNonce",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("mew-input", {
                          staticClass: "mb-8 SendOFflineDataInput",
                          attrs: {
                            label: "Data",
                            placeholder: "0x...",
                            "error-messages": _vm.dataErrors,
                            disabled: _vm.disableData,
                          },
                          model: {
                            value: _vm.data,
                            callback: function ($$v) {
                              _vm.data = $$v
                            },
                            expression: "data",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("mew-input", {
                          staticClass: "SendOfflineGasLimitInput",
                          attrs: {
                            label: _vm.$t("common.gas.limit"),
                            "error-messages": _vm.gasLimitErrors,
                            type: "number",
                          },
                          model: {
                            value: _vm.gasLimit,
                            callback: function ($$v) {
                              _vm.gasLimit = $$v
                            },
                            expression: "gasLimit",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("mew-input", {
                          staticClass: "SendOfflineGasPriceInput",
                          attrs: {
                            label: "Gas Price (in gwei)",
                            "error-messages": _vm.gasPriceErrors,
                            type: "number",
                          },
                          model: {
                            value: _vm.gasPrice,
                            callback: function ($$v) {
                              _vm.gasPrice = $$v
                            },
                            expression: "gasPrice",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "d-flex flex-column mt-12" }, [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("mew-button", {
                        staticClass: "SendOfflineGenerateTransactionButton",
                        attrs: {
                          title: "Generate Transaction",
                          "has-full-width": false,
                          "btn-size": "xlarge",
                          disabled: _vm.isDisabledNextBtn,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.generateTx.apply(null, arguments)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column justify-center" },
                        [
                          _c("input", {
                            ref: "upload",
                            staticClass: "SendOfflineUploadInput",
                            staticStyle: { opacity: "0" },
                            attrs: { type: "file", accept: "json" },
                            on: { change: _vm.upload },
                          }),
                          _c("mew-button", {
                            staticClass:
                              "mt-2 display--block mx-auto SendOfflineUploadJsonButton",
                            attrs: {
                              title: "Upload JSON file",
                              "btn-size": "small",
                              "btn-style": "transparent",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.$refs.upload.click()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c("mew-button", {
                        attrs: {
                          title: _vm.$t("common.clear-all"),
                          "has-full-width": false,
                          "btn-size": "small",
                          "btn-style": "transparent",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.clear()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "mew-overlay",
        {
          attrs: {
            "show-overlay": _vm.isSignedTxOpen,
            title: "Signed Transaction",
            close: function () {
              return (_vm.isSignedTxOpen = false)
            },
            "content-size": "xlarge",
          },
        },
        [
          _vm.signedTransaction
            ? _c(
                "div",
                { staticClass: "text-center", staticStyle: { width: "100%" } },
                [
                  _c("mew-text-area", {
                    ref: "signedTxInput",
                    staticClass: "SendOfflineSignedTxResultInput",
                    staticStyle: { width: "100%" },
                    attrs: {
                      label: "Signed Transaction",
                      readonly: "",
                      value: _vm.signedTransaction.rawTransaction,
                    },
                  }),
                  _c("mew-text-area", {
                    staticClass: "mt-12",
                    staticStyle: { width: "100%" },
                    attrs: {
                      label: "Signed Raw Transaction",
                      readonly: "",
                      value: _vm.signed,
                    },
                  }),
                  _c("mew-button", {
                    staticClass: "mt-5",
                    attrs: { title: "Copy And Continue" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.copyAndContinue.apply(null, arguments)
                      },
                    },
                  }),
                  _c("div", { staticClass: "pt-3" }, [
                    _c(
                      "a",
                      {
                        staticClass: "mew-body",
                        attrs: {
                          href: _vm.jsonFile,
                          download: _vm.jsonFileName,
                          rel: "noopener noreferrer",
                        },
                      },
                      [_vm._v("Download")]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }