// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/backgrounds/bg-ad-with-us.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".desktop-content[data-v-bc5a95ec] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-position: 100% bottom;\n}\n.desktop-content .banner-content-container[data-v-bc5a95ec] {\n  height: 800px;\n}\n.mobile-content[data-v-bc5a95ec] {\n  background-position: bottom center;\n  background-size: auto;\n  padding: 0 0 6rem 0;\n}\n.work-together-container[data-v-bc5a95ec] {\n  border: 1px solid rgba(255, 255, 255, 0.3);\n  border-radius: 15px;\n}\n.orbit-img[data-v-bc5a95ec] {\n  max-width: 500px;\n}", ""]);
// Exports
module.exports = exports;
